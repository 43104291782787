<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">Provodka Registratsiyasi</h3>
          </div>
        </div>
        <div class="card-body">
          <v-container fluid>
            <v-row justify="space-between">
              <v-col cols="6" class="pl-0">
                <v-autocomplete
                  :items="debetList"
                  v-model="debet_account"
                  :error-messages="debet_accountErrors"
                  item-text="name"
                  dense
                  item-value="id"
                  label="Debet account"
                  outlined
                  @input="$v.debet_account.$touch()"
                  @blur="$v.debet_account.$touch()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" class="pl-0">
                <v-autocomplete
                  :items="accountList"
                  v-model="credit_account"
                  :error-messages="credit_accountErrors"
                  item-text="name"
                  dense
                  item-value="id"
                  label="Kredit account"
                  outlined
                  @input="$v.credit_account.$touch()"
                  @blur="$v.credit_account.$touch()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" class="pl-0"></v-col>
              <v-col cols="12" md="4" class="pl-0">
                <v-dialog
                  ref="dialog"
                  v-model="menu"
                  :return-value.sync="oper_date"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :error-messages="oper_dateErrors"
                      outlined
                      :value="oper_date | reverse"
                      label="Sana"
                      append-icon="event"
                      readonly
                      dense
                      v-bind="attrs"
                      v-on="on"
                      @input="$v.oper_date.$touch()"
                      @blur="$v.oper_date.$touch()"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="oper_date" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn small color="error" @click="menu = false"
                      >Bekor qilish</v-btn
                    >
                    <v-btn
                      small
                      color="success"
                      @click="$refs.dialog.save(oper_date)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="4" class="pl-0">
                <v-text-field
                  v-model="amount"
                  v-currency="options"
                  :error-messages="amountErrors"
                  label="Summa"
                  dense
                  outlined
                  @input="$v.amount.$touch()"
                  @blur="$v.amount.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="pl-0">
                <v-textarea
                  rows="1"
                  v-model="comment"
                  label="Izoh"
                  dense
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
          <div class="d-flex justify-content-start border-top pt-10">
            <div class="mr-2">
              <button
                type="button"
                class="btn btn-light-success font-weight-bold"
                @click="save"
                :disabled="newProvodkaLoading"
              >
                <i v-if="newProvodkaLoading" class="el-icon-loading"></i>
                Saqlash
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style></style>

<script>
import Swal from 'sweetalert2'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { required } from 'vuelidate/lib/validators'
export default {
  data() {
    return {
      newProvodkaLoading: false,
      menu: false,
      oper_date: '',
      credit_account: '',
      amount: '',
      debet_account: '',
      comment: '',
      selectedCurrencyOption: 1,
      currencyCode: 'USD',
      distractionFree: false,
      hideCurrencySymbol: true,
      hideGroupingSymbol: true,
      hideNegligibleDecimalDigits: true,
      prefix: null,
      suffix: null,
      precisionEnabled: true,
      precisionRangeEnabled: false,
      precisionFixed: 0,
      precisionRange: [0, 20],
      valueRangeEnabled: false,
      valueRange: [0, 9999]
    }
  },
  validations: {
    oper_date: {
      required
    },
    credit_account: {
      required
    },
    amount: {
      required
    },
    debet_account: {
      required
    }
  },
  created() {
    this.$store.dispatch('getBalansAccountList')
  },
  filters: {
    reverse(val) {
      if (typeof val == 'string') return val.split('-').reverse().join('-')
      else return val
    }
  },
  computed: {
    options() {
      return {
        locale: 'zh-ZH',
        currency: [
          this.currencyCode,
          null,
          { prefix: this.prefix, suffix: this.suffix }
        ][this.selectedCurrencyOption],
        valueRange: this.valueRangeEnabled
          ? { min: this.valueRange[0], max: this.valueRange[1] }
          : undefined,
        precision: this.precisionEnabled
          ? this.precisionRangeEnabled
            ? { min: this.precisionRange[0], max: this.precisionRange[1] }
            : this.precisionFixed
          : undefined,
        distractionFree: this.distractionFree
          ? {
              hideNegligibleDecimalDigits: this.hideNegligibleDecimalDigits,
              hideCurrencySymbol: this.hideCurrencySymbol,
              hideGroupingSymbol: this.hideGroupingSymbol
            }
          : false,
        autoDecimalMode: false,
        valueAsInteger: false,
        allowNegative: false
      }
    },
    debetList() {
      const data = this.$store.state.requests.balansAccountList
      return data.filter((x) => x.id !== this.credit_account)
    },
    accountList() {
      const data = this.$store.state.requests.balansAccountList
      return data.filter((x) => x.id !== this.debet_account)
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    debet_accountErrors() {
      const errors = []
      if (!this.$v.debet_account.$dirty) return errors
      !this.$v.debet_account.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    oper_dateErrors() {
      const errors = []
      if (!this.$v.oper_date.$dirty) return errors
      !this.$v.oper_date.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    credit_accountErrors() {
      const errors = []
      if (!this.$v.credit_account.$dirty) return errors
      !this.$v.credit_account.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    amountErrors() {
      const errors = []
      if (!this.$v.amount.$dirty) return errors

      !this.$v.amount.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Moliya  ' },
      { title: 'Provodka Registratsiyasi' }
    ])
  },
  methods: {
    toFloat(val) {
      if (typeof val == 'string') return parseFloat(val.replace(/[ ,]/g, ''))
      else return val
    },
    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        Swal.fire({
          title: 'Xato',
          text: "Ma'lumotlar yuborilmadi!",
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        }).then(() => {
          console.log('err')
        })
      } else {
        this.submitStatus = 'PENDING'
        this.submitStatus = 'OK'
        const data = {
          debet_account: this.debet_account,
          credit_account: this.credit_account,
          amount: this.toFloat(this.amount),
          oper_date: this.oper_date,
          comment: this.comment
        }
        console.log(data)
        this.newProvodkaLoading = true
        this.$store
          .dispatch('createProvodkaList', data)
          .then(() => {
            this.newProvodkaLoading = false
          })
          .catch((err) => {
            this.newProvodkaLoading = false
            console.error(err)
          })
        this.$v.$reset()
      }
    }
  }
}
</script>
